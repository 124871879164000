<template>
  <div class="masthead">
    Infinitely Scalable<br />
    Instantly Available<br />
    Lightning-Fast
  </div>
  <div class="subhead">
    A lightweight block explorer for the Helium Network built for speed.<br />
    Built as demonstration of the Helium Static API.
  </div>
  
</template>

<script>
</script>

<style scoped>
.masthead {
  text-align: center;
  padding-top: 200px;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: -0.01em;
  color: #0F172A;
}
.subhead {
  margin-top: 48px;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #475569;
}
</style>
