<template>
  <nav>
    <router-link to="/">
      <img src="@/assets/hotspotfyi.svg" alt="Hotspot.fyi" class="logo"/>
    </router-link> 
    <div>
      <router-link to="/about">About</router-link> |
      <router-link
        to="/wallet/12yMswNDdFfM7r3XhzG7uhrPk8ywAwUhp8CZzusykbtCwXZycd4/"
        >wallet1</router-link
      >
      |
      <router-link
        to="/wallet/131p8zceua95MQNmF6jBeuxLT42jNWtHXsbgGnUvAaeJxgMCpJ9/"
        >wallet2</router-link
      >
      |
      <router-link
        to="/hotspot/11HyZd4Uwm8p8YCwiaSx5L473zhzDYiuQdBRSHushZPfRGL4nJh/"
        >hotspot1</router-link
      >
    </div>
    
  </nav>
  <router-view />
</template>

<style>
:root {
  --black: rgb(28,33,43);
  --ltGrey: #eee;
}

#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  color: var(--black);
}

nav {
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ltGrey);;
}

nav, .data-view {
  overflow: hidden;
  margin: 0 auto;
  max-width: 1200px;
}

@media screen and (max-width: 1260px) {
  nav, .data-view {
    margin: 0 30px;
  } 
}

@media screen and (max-width: 800px) {
  nav, .data-view {
    margin: 0 14px;
  } 
}

@media screen and (max-width: 500px) {
  nav, .data-view {
    margin: 0 4px;
  } 
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
